import { ExpenseType } from '@sharedModels/expense/expense-type';
import TravelDiarySettings from '@sharedModels/traveldiary-settings';
import UserEthicMessage from '@sharedModels/user/user-ethic-message';
import { SalarySupplement, ScheduleSetting, SettingConstraintDto, SubmissionDeadlineDto } from '@sharedClients/timereport-api-client';
import TimeReport from './time-report';
import { ElectronicReceiptValidExtension, PunchClockConfiguration } from '@sharedClients/timereport-api-client';

export default class TimeReportSettings {
  public scheduleSettings: ScheduleSetting[];
  public canViewUploadReceipts: boolean;
  public shouldShowAdditionalDocumentationLink: boolean;
  public shouldShowLyftCheckBox: boolean;
  public showCompensatoryTab: boolean;
  public showCarPlan: boolean;
  public showLocationQuestionnaireTab: boolean;
  public isApprovalRequested: boolean;
  public hasApprovers: boolean;
  public expenseTypes: ExpenseType[];
  public travelDiaryOptions: TravelDiarySettings;
  public ShowAdjustingOldTimeReportPopUp: boolean;
  public EthicMessages: UserEthicMessage[];
  public isUserGMC?: boolean;
  public isUserSMD?: boolean;
  public salarySupplements?: SalarySupplement[];
  public shouldUseTwoDecimal?: boolean;
  public userCountryKey : string;
  public settingCountryKey : string;
  public hasRestHours : boolean;
  public CarInformationBool : boolean;
  public shouldEnableReasonAndType: boolean;
  public userActiveDate: Date;
  public showDashboardPageBool: boolean;
  public timeReport: TimeReport;
  public electronicReceiptValidExtension: ElectronicReceiptValidExtension;
  public canSetApprovers: boolean;
  public punchClockConfiguration: PunchClockConfiguration;
  public showChargeabilityImpactConfig: boolean;
  public shouldEnableCashOutRequest: boolean;
  public shouldShowDisclaimer: boolean;
  public shouldEnableAllowanceApprovalPageAsApprover: boolean;
  public shouldShowSummaryCards: boolean;
  public shouldEnableAllowanceRequest: boolean;
  public userFullName: string;
  public submissionDeadline: SubmissionDeadlineDto;
  public shouldEnableOvertimeRequestEmployee: boolean;
  public shouldEnableOvertimeRequestApprover: boolean;
  public shouldShowReceiptType: boolean;
  public toastWanrningDisplay: boolean;
  public timeReportStatus?: string;
  public isCamUser: boolean;
  public shouldEnableActivityBool: boolean;
  public shouldEnableEncashmentPopup: boolean;
  public ShouldEnableWorkShiftApprover: boolean;
  public ShouldEnableWorkShiftRequest: boolean;
  public shouldEnableOnCallAndSWTimeApprover: boolean;
  public shouldEnableOnCallAndSWTimeRequest: boolean;
  public settingConstraints?: SettingConstraintDto[];
  public corporationCd?: string;
  public additionalTimeIndicator?: string;  
}