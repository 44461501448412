import { Component,  ElementRef, ViewChild } from '@angular/core';
import { ExpenseMultipleTaxiGrid } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'myte-taxi-amount-render',
  templateUrl: './taxi-amount-render.component.html',
  styleUrls: ['./taxi-amount-render.component.sass']
})
export class TaxiAmountRenderComponent implements ICellRendererAngularComp {
  public validations: RegExp[] = [];
  public amount: string;
  public isReadOnly = true;
  @ViewChild('textboxComponent', { static: false }) textbox: ElementRef;

  constructor() { }

  public agInit(params: ICellRendererParams<ExpenseMultipleTaxiGrid, string>): void {
    this.amount = params.value;
    let decimalSeparator = (1.1).toLocaleString(navigator.language).substring(1, 2);
    let regExpForDecimals = new RegExp(`^[0-9]{0,9}(\\${decimalSeparator}[0-9]{0,2})?$`);
    this.validations.push(regExpForDecimals);
  }

  public refresh(params: ICellRendererParams<ExpenseMultipleTaxiGrid, string>): boolean {
    return false;
  }

  public focusTextbox() {
    this.isReadOnly = false;
    if (this.textbox && this.textbox.nativeElement) {
      this.textbox.nativeElement.readOnly = false;
      setTimeout(() => {
        this.textbox.nativeElement.focus();
      });
    }
  }
}
