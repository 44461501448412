import { ProjectDescendantTreeValidationError, NetworkHierarchy, ProjectType } from '@sharedClients/time-api-client';

export default class TimeEntryAssignment {
    public code: string = '';
    public description: string = '';
    public countryRegion: string = '';
    public type: string; // TODO (m.fernandez.bortolas) Create Enum to identify if the WBS is Holiday, Static, etc
    public subtype: string;
    public client: string = '';
    public tooltip: string;
    public hasError: boolean;
    public hasAdjustment: boolean;
    public selected: boolean;
    public isEmpty: boolean;
    public isHoliday: boolean;
    public masterServicesAgreement: string;
    public isValid: boolean = true;
    public hasNetWorks: boolean;
    public hasCostCollectors: boolean;
    public hasDismissCheckboxChecked: boolean;
    public hasInvalidStaticProject: boolean;
    public hasSuspenseExpenseProject: boolean;
    public isDisplay: boolean;
    public treeValidationError: ProjectDescendantTreeValidationError;
    public treeNetworkHierarchy: NetworkHierarchy;
    public isLeaf: boolean;
    public isFederalProject: boolean;
    public projectType: ProjectType;
    public isProductive: boolean;

    public get displayName(): string {        
        let displayName: string = '';        
        if(this.code){            
            let useOnlyCodeToDisplayName = this.code == "Assigned Location" || this.code == "Company Code/Cost Center" || this.code == "Work Location";
            displayName = useOnlyCodeToDisplayName ? `${this.code}`:`${this.description} (${this.code})`;
        }
        return displayName;    
    }

    public get tooltipDisplay(): string {
        var assignmentTooltip = `\n Client: ${this.client} \n Country/Region: ${this.countryRegion} \n`;
        if (this.tooltip != null) {
            return assignmentTooltip + `\n`;
        }
        else {
            return assignmentTooltip;
        }
    }
}
