import {
  TimeSheetOutput,
  WorkScheduleOutput,
  TimeSheetInput,
  ViewTask,
  Assignment,
  ViewTimeEntry,
  ViewTimeCategoryTask,
  ViewTimeCategoryEntry,
  WorkScheduleInputDto,
  WorkScheduleCharge as WorkScheduleChargeDto,
  PunchClockConfigAndEntriesOutput,
  PunchClockEntryFromDb,
  WorkStartTime,
  WorkEndTime,
  MealEndTime,
  MealStartTime,
  SavePunchClockEntriesInput,
  ShiftScheduleDto,
  ShiftScheduleOutput,
  PunchClockEntryToSave,
  ShiftScheduleInput,
  TimeCategoryUIType as ViewTimeCategoryUIType,
  AllowanceRequestEntryOutput,
  AllowanceRequestEntriesInput,
  ViewAllowanceRequestEntry,
  AllowanceRequestOutput,
  TimeCategoryRequestEntry,
  TimeCategoryRequestOutput,
  TimeCategoryRequestInput,
  TimeCategoryRequestInputEntry,
  TimeCategoryRequestInputEntryValue
} from '@sharedClients/time-api-client';
import TimeSheet from '@sharedModels/time-sheet/time-sheet';
import TimeSheetDate from '@sharedModels/time-sheet/time-sheet-date';
import TimeEntry from '@sharedModels/time-sheet/time-entry/time-entry';
import TimeEntryTask from '@sharedModels/time-sheet/time-entry/time-entry-task';
import TimeEntryAssignment from '@sharedModels/time-sheet/time-entry/time-entry-assignment';
import TimeEntryCharge from '@sharedModels/time-sheet/time-entry/time-entry-charge';
import TimeCategoryCharge from '@sharedModels/time-sheet/time-category/time-category-charge';
import TimeCategory from '@sharedModels/time-sheet/time-category/time-category';
import { TimeCategoryTask, TimeCategoryUIType } from '@sharedModels/time-sheet/time-category/time-category-task';
import { WorkSchedule, WorkScheduleType, SimpleWorkScheduleCharge, WorkScheduleChargeForPeriod, WorkScheduleGroup } from '@sharedModels/time-sheet/work-schedule/time-sheet-work-schedule';
import MyteApiMessage from '@sharedModels/myte-api-message';
import { PunchClock, PunchClockEntry } from '@sharedModels/time-sheet/punch-clock';
import { ProjectInfo } from '@sharedClients/chargecode-api-client';
import { ShiftSchedule } from '@sharedModels/time-sheet/shift-schedule/shift-schedule';
import { ShiftScheduleOption } from '@sharedModels/time-sheet/shift-schedule/shift-schedule-option';
import { ShiftScheduleRowData } from '@sharedModels/time-sheet/shift-schedule/shift-schedule-row-data';
import { ShiftScheduleEntry } from '@sharedModels/time-sheet/shift-schedule/shift-schedule-entry';
import * as moment from 'moment';
import { LocalizeFormatUtils } from '@sharedUtils/localizeFormater.utils';
import { ValidationErrorOutPut } from '@sharedClients/timereport-api-client';
import { OvertimeRequest } from '@shared/models/time-sheet/overtime-request/overtime-request';
import { OvertimeRequestEntry } from '@shared/models/time-sheet/overtime-request/overtime-request-entry';
import { ChargeCodeInfo } from '@shared/models/charge-code/charge-code-info';
import { WorkShift } from '@shared/models/time-sheet/work-shift/work-shift';
import { WorkShiftEntry } from '@shared/models/time-sheet/work-shift/work-shift-entry';
import { WorkShiftKey } from '@shared/utils/constants';

export default class TimeServiceMapper {
  
  public static mapTimesheet(res: TimeSheetOutput, ownerEid: string, modified?: boolean ,validationErrorOutPut ? :ValidationErrorOutPut[], hasRestHours: boolean = false): TimeSheet {    
    let timesheet = new TimeSheet();
    timesheet.isSaved = res.isSaved;
    timesheet.isEditable = res.isEditable;
    timesheet.modified = modified ?? false;
    timesheet.shouldResave = res.shouldResave;
    timesheet.isESTravelPunchClock = res.isESTravelPunchClock;
    this.mapTimesheetDates(timesheet, res);

    let timeEntry = new TimeEntry();
    timeEntry.validationErrorOutPut =validationErrorOutPut;
    timeEntry.tasks = this.mapTimeEntryTasks(timesheet, res);

    timesheet.timeEntry = timeEntry;
    timesheet.timeReportStatus = res.timeReportStatus;
    timesheet.userFullName = res.userFullName;
    let timeCategory = new TimeCategory();

    if (res.timeSheetShiftSchedule != null && res.timeSheetShiftSchedule.totals.length > 0) {
      let shiftSchedule = this.mapShiftScheduleTimeCategory(timesheet, res);
      timeCategory.tasks.push(shiftSchedule);
    } else {
      let workSchedule = this.mapWorkScheduleTimeCategory(timesheet, res);
      timeCategory.tasks.push(workSchedule);
    }

    timeCategory.tasks = this.mapTimeCategoryTasks(timesheet, res, timeCategory);

    if (res.restHours) {
      let taskRestHours = this.mapPunchClockTimeCategory(timesheet, res, true);
      timeCategory.tasks.push(taskRestHours);
      timesheet = this.applyWorkingHoursErrorOnEmptyCells(timesheet, taskRestHours);
    }
    if (res.workingHours) {
      let taskPunchClock = this.mapPunchClockTimeCategory(timesheet, res, false);
      timeCategory.tasks.push(taskPunchClock);
      timesheet = this.applyWorkingHoursErrorOnEmptyCells(timesheet, taskPunchClock);
    }

    timesheet.timeCategory = timeCategory;
    timesheet.messages = res.messages
      ? res.messages.map(msg => new MyteApiMessage(msg.text, msg.category))
      : [];

    timesheet.timeEntryTemplateAutomaticMessage = res.timeEntryTemplateAutomaticMessage;
    timesheet.assignments = this.mapAssignments(res.projectInfos);
    timesheet.ownerEid = ownerEid;
    timesheet.assignedLocation = res.assignedLocation;
    timesheet.companyHistoryList = res.companyAndCostCenterHistoryList;
    timesheet.timeCategoryForWorkShift = res.timeCategoryForWorkShift;
    return timesheet;
  }

  private static mapTimesheetDates(timesheet: TimeSheet, res: TimeSheetOutput): void {
    res.timePeriodDates.forEach((timePeriodDate) => {
      let timesheetDate = new TimeSheetDate();
      timesheetDate.date = timePeriodDate.date;
      timesheetDate.isHoliday = timePeriodDate.isHoliday;
      timesheetDate.isWeekend = timePeriodDate.isWeekend;
      timesheetDate.isActive = timePeriodDate.isActiveDay;
      timesheetDate.day = timePeriodDate.day;
      timesheet.timeSheetDates.push(timesheetDate);
    });
  }

  private static mapTimeEntryTasks(timesheet: TimeSheet, res: TimeSheetOutput): TimeEntryTask[] {
    let tasks = res.tasks.map(task => {
      let timeEntrytask = new TimeEntryTask();
      timeEntrytask.assignment = new TimeEntryAssignment();
      timeEntrytask.assignment.code = task.assignment.projectNumber;
      timeEntrytask.assignment.description = task.assignment.projectDescription;
      timeEntrytask.assignment.tooltip = LocalizeFormatUtils.localizeTooltip(task.assignment.tooltip,true); // TODO: Add property on API
      timeEntrytask.assignment.hasError = task.assignment.hasError;
      timeEntrytask.assignment.hasAdjustment = task.assignment.hasAdjustment; // TODO: Add property on API
      timeEntrytask.assignment.type = task.assignment.projectType.toString();
      timeEntrytask.sequenceNumber = task.sequenceNumber;
      timeEntrytask.assignment.isFederalProject = task.assignment.isFederalProject;// TODO: Add IsFederal property
      timeEntrytask.assignment.hasNetWorks = task.assignment.hasNetWorks;
      timeEntrytask.assignment.hasCostCollectors = task.assignment.hasCostCollectors;
      timeEntrytask.assignment.treeNetworkHierarchy = task.assignment.treeNetworkHierarchy;
      timeEntrytask.assignment.treeValidationError = task.assignment.treeValidationError;
      timeEntrytask.assignment.hasDismissCheckboxChecked = task.assignment.hasDismissCheckboxChecked;
      timeEntrytask.assignment.hasInvalidStaticProject = task.assignment.hasInvalidStaticProject;
      timeEntrytask.assignment.hasSuspenseExpenseProject = task.assignment.hasSuspenseExpenseProject;
      timeEntrytask.assignment.isLeaf = task.assignment.isLeaf;
      timeEntrytask.assignment.projectType = task.assignment.projectType;
      timeEntrytask.assignment.isValid = task.assignment.isValid;
      timeEntrytask.assignment.isHoliday = task.assignment.isHolidayProj;
      timeEntrytask.assignment.isProductive = task.assignment.isProductive;
      timeEntrytask.charges = this.mapTimeEntryCharges(timesheet, task);
      timeEntrytask.tooltip = LocalizeFormatUtils.localizeTooltip(task.tooltip,true,true);
      timeEntrytask.status = task.status;
      timeEntrytask.isDeletable = task.isDeletable;
      timeEntrytask.isEditable = !task.isReadOnly && timesheet.isEditable;
      return timeEntrytask;
    });

    return tasks;
  }

  private static mapTimeEntryCharges(timesheet: TimeSheet, task: ViewTask): TimeEntryCharge[] {
    let charges = timesheet.timeSheetDates.map(timesheetDate => {
      let timeEntryCharge = new TimeEntryCharge();
      timeEntryCharge.date = timesheetDate.date;
      let entry = task.timeEntries.filter(x => new Date(x.date).getDate() == timesheetDate.date.getDate())[0];
      timeEntryCharge.value = entry ? entry.hours : 0;
      timeEntryCharge.tooltip = entry ? LocalizeFormatUtils.localizeTooltip(entry.tooltip,true,true)  : null;
      timeEntryCharge.hasError = entry ? entry.hasError : false;
      timeEntryCharge.hasAdjustment = entry ? entry.hasAdjustment : false;
      timeEntryCharge.displayErrorInTotalHour = entry ? entry.isShowErrorInTotalHour : false;
      timeEntryCharge.isPolicy818 = entry ? entry.isPolicy818 : false;
      timeEntryCharge.isMalaysiaValidation = entry ? entry.isMalaysiaValidation : false;
      timeEntryCharge.isGDNValidation = entry ? entry.isGDNValidation : false;
      timeEntryCharge.errorType = entry ? entry.errorType : null;
      return timeEntryCharge;
    });

    return charges;
  }
  private static mapShiftScheduleTimeCategory(timesheet: TimeSheet, res: TimeSheetOutput): TimeCategoryTask {
    let shiftSchedule = new TimeCategoryTask();
    shiftSchedule.name = shiftSchedule.tooltip = res.timeSheetShiftSchedule.shiftType;
    shiftSchedule.code = 'S';
    shiftSchedule.isEditable = timesheet.isEditable;
    shiftSchedule.UiType = TimeCategoryUIType.TextBox;
    shiftSchedule.totalHours = res.timeSheetShiftSchedule.totalHours;
    shiftSchedule.charges = timesheet.timeSheetDates.map(timesheetDate => {
      let shiftScheduleCharge = new TimeCategoryCharge();
      shiftScheduleCharge.date = timesheetDate.date;
      let entry = res.timeSheetShiftSchedule.totals.filter(x => new Date(x.date).getDate() == timesheetDate.date.getDate())[0];
      shiftScheduleCharge.value = entry ? entry.value : 0;
      shiftScheduleCharge.tooltip = null;
      shiftScheduleCharge.hasError = false;
      shiftScheduleCharge.hasAdjustment = false;
      shiftScheduleCharge.isEditable = false;
      shiftScheduleCharge.isCustomDay = entry.isCustomDate;
      return shiftScheduleCharge;
    });

    return shiftSchedule;
  }

  private static mapWorkScheduleTimeCategory(timesheet: TimeSheet, res: TimeSheetOutput): TimeCategoryTask {
    let workSchedule = new TimeCategoryTask();
    workSchedule.name = workSchedule.tooltip = 'Work Schedule';
    workSchedule.code = 'W';
    workSchedule.isEditable = res.timeSheetWorkSchedule.shifts[0].editable;
    workSchedule.UiType = TimeCategoryUIType.TextBox;
    workSchedule.totalHours = res.timeSheetWorkSchedule.shifts[0].totalHours;
    workSchedule.typeWS = res.timeSheetWorkSchedule.type;
    workSchedule.charges = timesheet.timeSheetDates.map(timesheetDate => {
      let workScheduleCharge = new TimeCategoryCharge();
      workScheduleCharge.date = timesheetDate.date;
      let entry = res.timeSheetWorkSchedule.shifts[0].charges
        .filter(x => new Date(x.date).getDate() == timesheetDate.date.getDate())[0];
      workScheduleCharge.value = entry ? entry.value : 0;
      workScheduleCharge.tooltip = null;
      workScheduleCharge.hasError = false;
      workScheduleCharge.hasAdjustment = false;
      workScheduleCharge.isEditable = false;
      return workScheduleCharge;
    });

    return workSchedule;
  }

  private static mapPunchClockTimeCategory(timesheet: TimeSheet, res: TimeSheetOutput, isRestHours: boolean): TimeCategoryTask {
    let taskPunchClock = new TimeCategoryTask();
    taskPunchClock.name = isRestHours ? 'Rest Hours' : 'Working Hours';
    taskPunchClock.UiType = TimeCategoryUIType.TextBox;
    taskPunchClock.code = isRestHours ? 'H' : 'P';
    taskPunchClock.isEditable = true;
    taskPunchClock.totalHours = isRestHours ? res.restHours.totalHours : res.workingHours.totalHours;
    taskPunchClock.charges = timesheet.timeSheetDates.map(timeSheetDate => {
    let punchClockCharge = new TimeCategoryCharge();
    punchClockCharge.date = timeSheetDate.date;
    let entry = isRestHours ? res.restHours.entries.find(x => new Date(x.date).getDate() == timeSheetDate.date.getDate()) : res.workingHours.entries.find(x => new Date(x.date).getDate() == timeSheetDate.date.getDate());
    punchClockCharge.value = entry ? entry.value : 0;
    punchClockCharge.tooltip = entry.tooltip;
    punchClockCharge.hasError = entry.hasError;
    punchClockCharge.hasAdjustment = entry ? entry.hasAdjustment : false;
    punchClockCharge.isEditable = false;
    punchClockCharge.errorType = entry.errorType;
    return punchClockCharge;
    });

    return taskPunchClock;
  }

  private static mapTimeCategoryTasks(timesheet: TimeSheet, res: TimeSheetOutput, timeCategory: TimeCategory): TimeCategoryTask[] {
    let timeCategoryTasks = timeCategory.tasks.concat(res.timeCategoryTask.map(task => {
      let timeCategoryTask = new TimeCategoryTask();
      timeCategoryTask.code = task.code;
      timeCategoryTask.name = task.name;
      timeCategoryTask.tooltip = task.tooltip;
      timeCategoryTask.isEditable = !task.isReadOnly && timesheet.isEditable;
      timeCategoryTask.totalHours = task.totalHours;
      timeCategoryTask.isHyperlink = task.shouldEnableHyperlink;

      switch (task.uiType) {
        case 0:
          timeCategoryTask.UiType = TimeCategoryUIType.TextBox;
          break;
        case 1:
          timeCategoryTask.UiType = TimeCategoryUIType.CheckBox;
          break;
        case 2:
          timeCategoryTask.UiType = TimeCategoryUIType.None;
          break;
        default:
          timeCategoryTask.UiType = TimeCategoryUIType.None;
          break;
      }

      timeCategoryTask.charges = this.mapTimeCategoryCharges(timesheet, task, timeCategoryTask);
      return timeCategoryTask;
    }));

    return timeCategoryTasks;
  }

  private static mapTimeCategoryCharges(timesheet: TimeSheet, task: ViewTimeCategoryTask, timeCategoryTask: TimeCategoryTask): TimeCategoryCharge[] {
    let charges = timesheet.timeSheetDates.map(timesheetDate => {
      let timeCategoryCharge = new TimeCategoryCharge();
      timeCategoryCharge.date = timesheetDate.date;
      let entry = task.timeCategoryEntries?.filter(x => new Date(x.date).getDate() == timesheetDate.date.getDate())[0];
      timeCategoryCharge.value = entry && entry.hours ? entry.hours : 0;
      timeCategoryCharge.value = entry ? entry.hours : 0;
      timeCategoryCharge.tooltip = entry ? entry.tooltip : null;
      timeCategoryCharge.hasError = entry ? entry.hasError : false;
      timeCategoryCharge.hasAdjustment = entry ? entry.hasAdjustment : false;
      timeCategoryCharge.isEditable = entry ? entry.isEditable : timeCategoryTask.isEditable;      
      return timeCategoryCharge;
    });
    return charges;
  }

  public static applyWorkingHoursErrorOnEmptyCells(timesheet: TimeSheet, punchClockTask: TimeCategoryTask): TimeSheet {
    let totalHoursByDay = 0;
    punchClockTask.charges.forEach(pcEntry => {
      timesheet.timeEntry.tasks.forEach(timeEntry => {
        totalHoursByDay += timeEntry.charges.find(timeEntry => timeEntry.date == pcEntry.date)?.value;
      });
      if (totalHoursByDay !== pcEntry.value) {
        timesheet.timeEntry.tasks.forEach(te => {
          let timeE = te.charges.find(timeEntry => timeEntry.date == pcEntry.date);
          if (timeE && timeE.value === 0) {
            timeE.hasError = pcEntry.hasError ? pcEntry.hasError : timeE.hasError;
            timeE.tooltip = pcEntry.tooltip ? pcEntry.tooltip : timeE.tooltip;
          }
        });
      }
    });
    return timesheet;
  }

  public static mapChargeCodes(res: ProjectInfo[] | ChargeCodeInfo[]): TimeEntryAssignment[] {
    return res.map(c => {
      let chargeCode = new TimeEntryAssignment();

      let businessActivityLevel = c.businessActivityLevel.toLocaleUpperCase();
      let productivityCategory = 'CHG';
      let type: string;
      let subType: string;

      if (c.productivityCategory == productivityCategory) {
        type = 'Chargeable';
      }
      else if (businessActivityLevel.startsWith('A1005')) {
        subType = 'Client Relationship Development';
        type = 'Business Development';
      }
      else if (businessActivityLevel.startsWith('A1010')) {
        subType = 'Opportunity Pursuit';
        type = 'Business Development';
      }
      else if (businessActivityLevel.startsWith('A1015')) {
        subType = 'Sales Leadership';
        type = 'Business Development';
      }
      else if (businessActivityLevel.startsWith('A3005') || businessActivityLevel.startsWith('A3010')) {
        subType = 'Training';
        type = 'Training/Recruiting/Absence';
      }
      else if (businessActivityLevel.startsWith('A3015')) {
        subType = 'Recruiting';
        type = 'Training/Recruiting/Absence';
      }
      else if (businessActivityLevel.startsWith('A35')) {
        subType = 'Absence';
        type = 'Training/Recruiting/Absence';
      }
      else
        type = 'Other';

      chargeCode.hasInvalidStaticProject = c.projectDetailInfo.additionalInfo == 'StaticProjectIsInvalid';
      chargeCode.type = type;
      chargeCode.subtype = subType;
      chargeCode.countryRegion = c.country;
      chargeCode.client = c.clientName;
      chargeCode.code = c.externalNumber;
      chargeCode.description = c.description;
      chargeCode.hasError = !(c.valid && c.validForUser);
      chargeCode.isHoliday = c.isHoliday;
      chargeCode.masterServicesAgreement = c.masterServicesAgreement;
      chargeCode.tooltip = c.toolTip;
      chargeCode.isValid = (c.valid && c.validForUser);
      chargeCode.hasNetWorks = c.hasNetworks;
      chargeCode.hasDismissCheckboxChecked = c.hasDismissCheckboxChecked;
      chargeCode.hasCostCollectors = c.hasCostCollectors;
      chargeCode.isDisplay = c.isDisplay;
      chargeCode.treeNetworkHierarchy = c.treeNetworkHierarchy;
      chargeCode.treeValidationError = c.treeValidationError;
      chargeCode.isLeaf = c.isLeaf;
      return chargeCode;
    });
  }

  public static mapShiftSchedules(res: ShiftScheduleOutput): ShiftSchedule {
    let shiftSchedule = new ShiftSchedule();
    shiftSchedule.shiftType = res.shiftType;
    shiftSchedule.isByPeriod = res.isByPeriod;
    shiftSchedule.error = res.error;
    shiftSchedule.isEditable = res.isEditable;
    shiftSchedule.timeReportStatus = res.timeReportStatus;

    if (res.isByPeriod) {
      shiftSchedule.dates = res.startTimes.map(st => st.date);
    } else {
      if (res.shiftType == 'Custom Schedule')
        this.MapStartwithSundayByWeek(res);
      shiftSchedule.daysOfWeek = res.startTimes.map(st => st.dayOfWeek);
    }

    shiftSchedule.shiftScheduleOptions = res.shiftSchedulesOptions?.map((option, index) => {
      let shiftScheduleOption = new ShiftScheduleOption();
      shiftScheduleOption.key = index;
      shiftScheduleOption.description = option.description;
      shiftScheduleOption.startTime = option.startTime;
      shiftScheduleOption.endTime = option.endTime;
      shiftScheduleOption.breakLength = option.breakLength;
      shiftScheduleOption.total = option.total;
      return shiftScheduleOption;
    });

    let rows = [];
    if (res.shiftType == 'Custom Schedule')
      rows = ['Shift Start Time', 'Break Start Time', 'Break End Time', 'Shift End Time', 'Rest Day', 'Total Hours'];
    else
      rows = ['Start Time', 'End Time', 'Break Length', 'Rest Day', 'Total Hours'];

    for (let i in rows) {
      let row = new ShiftScheduleRowData();
      row.description = rows[i];
      row.checkboxRow = row.description === 'Rest Day';
      row.entries = TimeServiceMapper.mapShiftEntries(res, row.description, shiftSchedule.dates);
      row.isEditable = res.isEditable;
      shiftSchedule.shiftScheduleData.push(row);
    }

    return shiftSchedule;
  }

  private static mapShiftEntries(res: ShiftScheduleOutput, rowDescription: string, timePeriodDates: Date[]): ShiftScheduleEntry[] {

    switch (rowDescription) {
      case "Start Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.startTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "Shift Start Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.startTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "End Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.endTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "Shift End Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.endTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "Break Length":
        return TimeServiceMapper.mapShiftScheduleEntry(res.breakLengths, res.restDays, res.isByPeriod, timePeriodDates);
      case "Break Start Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.breakStartTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "Break End Time":
        return TimeServiceMapper.mapShiftScheduleEntry(res.breakEndTimes, res.restDays, res.isByPeriod, timePeriodDates);
      case "Rest Day":
        return TimeServiceMapper.mapShiftScheduleEntry(null, res.restDays, res.isByPeriod, timePeriodDates);
      case "Total Hours":
        return TimeServiceMapper.mapShiftScheduleEntry(res.totals, res.restDays, res.isByPeriod, timePeriodDates);
    }
  }

  private static MapStartwithSundayByWeek(res: ShiftScheduleOutput){
    res.startTimes = this.MapValueStartwithSunday(res.startTimes);
    res.endTimes = this.MapValueStartwithSunday(res.endTimes);
    res.breakStartTimes = this.MapValueStartwithSunday(res.breakStartTimes);
    res.breakEndTimes = this.MapValueStartwithSunday(res.breakEndTimes);
    res.restDays = this.MapValueStartwithSunday(res.restDays);
    res.totals = this.MapValueStartwithSunday(res.totals);
  }

  private static MapValueStartwithSunday(values: any[]): any[] {
    let mondayHourArray = values.slice(0, 6);
    let sundayHourArray = values.slice(6, 7);
    return sundayHourArray.concat(mondayHourArray);
  }

  private static mapShiftScheduleEntry(rowEntries: ShiftScheduleDto[], restDayRow: boolean[], isByPeriod: boolean, timePeriodDates: Date[]): ShiftScheduleEntry[] {

    let daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let entries = [];
    let array = rowEntries == null ? restDayRow : rowEntries;
    for (let i in array) {
      let entry = new ShiftScheduleEntry();
      entry.date = isByPeriod ? timePeriodDates[i] : null;
      entry.day = isByPeriod ? moment(timePeriodDates[i]).format('dddd') : daysOfWeek[i];
      entry.value = rowEntries == null ? restDayRow[i] : rowEntries[i].value;
      entry.visible = !restDayRow[i];
      entries.push(entry);
    }

    return entries;
  }

  public static mapShiftSchedulesCustomDay(res: ShiftScheduleOutput): ShiftSchedule {
    let shiftSchedule = new ShiftSchedule();
    shiftSchedule.shiftType = res.shiftType;
    shiftSchedule.error = res.error;
    shiftSchedule.isEditable = res.isEditable;

    shiftSchedule.shiftScheduleOptions = res.shiftSchedulesOptions.map((option, index) => {
      let shiftScheduleOption = new ShiftScheduleOption();
      shiftScheduleOption.key = index;
      shiftScheduleOption.description = option.description;
      shiftScheduleOption.startTime = option.startTime;
      shiftScheduleOption.endTime = option.endTime;
      shiftScheduleOption.breakLength = option.breakLength;
      shiftScheduleOption.total = option.total;
      return shiftScheduleOption;
    });

    return shiftSchedule;
  }

  public static mapWorkSchedules(res: WorkScheduleOutput): WorkScheduleGroup[] {
    let workScheduleGroups = res.workSchedules.map(ws => {
      let workScheduleGroup: WorkScheduleGroup = new WorkScheduleGroup();
      for (let i = 0; i < ws.shifts.length; i++) {
        let workSchedule = new WorkSchedule({ shift: ws.shifts[i].shiftType });
        workSchedule.editable = ws.shifts[i].editable;
        workSchedule.isInvalid = false;
        workSchedule.charges = ws.shifts[i].charges.map(c => {
          if (WorkScheduleType.getValueByString(ws.type) == WorkScheduleType.CustomForPeriod)
            return new WorkScheduleChargeForPeriod(c.date, +c.value);
          return new SimpleWorkScheduleCharge(+c.value, c.dayOfWeek);
        });
        workScheduleGroup.id = ws.id;
        workScheduleGroup.displayName = ws.displayName;
        workScheduleGroup.default = ws.default;
        workScheduleGroup.type = WorkScheduleType.getValueByString(ws.type) ? WorkScheduleType.getValueByString(ws.type) : WorkScheduleType.Default;;
        workScheduleGroup.staticWorkScheduleId = ws.staticWorkScheduleId;

        workScheduleGroup.WorkSchedules.push(workSchedule);
      };
      return workScheduleGroup;
    });

    let selectedWorkSchedule = workScheduleGroups.filter(x => x.id === res.selectedWorkScheduleId)[0];
    if (selectedWorkSchedule)
      selectedWorkSchedule.selected = true;

    return workScheduleGroups;
  }

  public static mapTimeSheetToDto(ts: TimeSheet, periodEnd: Date, enterpriseId: string): TimeSheetInput {
    let dto = new TimeSheetInput();
    dto.enterpriseId = enterpriseId;
    dto.periodEnd = periodEnd;

    dto.tasks = ts.timeEntry.tasks.filter(task => !task.assignment.isEmpty && task.assignment.code != "Company Code/Cost Center" && task.assignment.code != "Assigned Location" && task.assignment.code != "Work Location").map(task => {
      let viewTask = new ViewTask();
      viewTask.assignment = new Assignment();
      viewTask.assignment.projectNumber = task.assignment.code;
      viewTask.sequenceNumber = task.sequenceNumber;
      
      viewTask.timeEntries = task.charges.map(charge => {
        let viewTimeEntry = new ViewTimeEntry();
        viewTimeEntry.date = charge.date;
        viewTimeEntry.hours = charge.value;
        return viewTimeEntry;
      });

      return viewTask;
    });

    dto.timeCategoryTasks = ts.timeCategory.tasks.map(task => {
      let viewCategoryTask = new ViewTimeCategoryTask();
      viewCategoryTask.code = task.code;

      viewCategoryTask.timeCategoryEntries = task.charges.map((charge, index) => {
        let viewCategoryEntry = new ViewTimeCategoryEntry();
        viewCategoryEntry.date = charge.date;
        // TODO: OnCall task: Send boolean value and calculate hours into API
        if (task.UiType === TimeCategoryUIType.CheckBox) {
          viewCategoryTask.uiType = ViewTimeCategoryUIType._1;
          viewCategoryEntry.hours = charge.value as boolean ? charge.value : null;
        } else {
          viewCategoryEntry.hours = charge.value;
        }

        return viewCategoryEntry;
      });

      return viewCategoryTask;
    });

    return dto;
  }

  public static mapWorkScheduleToDto(wsg: WorkScheduleGroup): WorkScheduleInputDto {
    let wsInputDto = new WorkScheduleInputDto();
    wsInputDto.id = wsg.id;
    wsInputDto.staticWorkScheduleId = wsg.staticWorkScheduleId;
    wsInputDto.type = wsg.displayName;
    wsInputDto.charges = this.mapWorkScheduleChargesToDto(wsg);
    return wsInputDto;
  }

  public static mapWorkScheduleCannotAllowWeekendsToDto(wsg: WorkScheduleGroup): WorkScheduleInputDto {
    let wsInputDto = new WorkScheduleInputDto();
    wsInputDto.id = wsg.id;
    wsInputDto.staticWorkScheduleId = wsg.staticWorkScheduleId;
    wsInputDto.type = wsg.displayName;
    wsInputDto.charges = this.mapWorkScheduleChargesCannotAllowWeekendsToDto(wsg);
    return wsInputDto;
  }

  public static remapAssignments(ts: TimeSheet, assignments: TimeEntryAssignment[]): TimeSheet {
    ts.assignments = assignments;

    ts.timeEntry.tasks.forEach(task => {
      let assignment = ts.assignments.filter(x => x.code === task.assignment.code)[0];
      if (assignment) {
        assignment.selected = true;
        task.assignment.description = assignment.description;
        task.assignment.isHoliday = assignment.isHoliday;
        task.assignment.isValid = assignment.isValid;
      }
    });

    return ts;
  }

  public static mapPuchClock(punchClockOutput: PunchClockConfigAndEntriesOutput): PunchClock {
    let punchClock = new PunchClock();

    punchClock.configuration = {
      countryCd: punchClockOutput.configuration.countryCd,
      minutesIncrement: punchClockOutput.configuration.minutesIncrementAmt,
      use24HsFormat: punchClockOutput.configuration.use24HsFormat,
      displayBreakDuration: punchClockOutput.configuration.displayBreakDuration,
      displayAddRowButton: punchClockOutput.configuration.displayAddRowButton,
      displayMealTime: punchClockOutput.configuration.displayMealTime,
      displayCopyButton: punchClockOutput.configuration.displayCopyButton,
      displayClearAllButton: punchClockOutput.configuration.displayClearAllButton,
      displayRemoveRowButton: punchClockOutput.configuration.displayRemoveRowButton,
      headerStaticMessage: punchClockOutput.configuration.headerStaticMessage,
      titleColumnBreakDuration: punchClockOutput.configuration.titleColumnBreakDuration,
      shouldChangeDefaultTimeIndicator: punchClockOutput.configuration.shouldChangeDefaultTimeIndicator
    };
    punchClock.message = punchClockOutput.configuration.headerStaticMessage;
    punchClock.editable = punchClockOutput.isEditable;
    punchClock.isReadOnly = punchClockOutput.isReadOnly;
    punchClock.isClearAllButtonEnabled = punchClockOutput.isClearAllButtonEnabled;
    punchClock.entries = punchClockOutput.entries.map(entry => (({
      dateTime: entry.dateTime,
      sequenceNumber: entry.daySequenceNbr,
      editable: entry.isDayEditable,
      nonWorkingDay: entry.isNonWorkingDay,
      hasError: false,

      workStartTime: {
        hours: entry.workStartTime.hours,
        minutes: entry.workStartTime.minutes
      },

      workEndTime: {
        hours: entry.workEndTime.hours,
        minutes: entry.workEndTime.minutes
      },

      mealStartTime: entry.mealStartTime ? {
        hours: entry.mealStartTime.hours,
        minutes: entry.mealStartTime.minutes
      } : null,

      mealEndTime: entry.mealEndTime ? {
        hours: entry.mealEndTime.hours,
        minutes: entry.mealEndTime.minutes
      } : null,

      breakDuration: entry.breakDuration,
      sourceSystem: entry.sourceSystem
    }) as PunchClockEntry));
    punchClock.entries = punchClock.entries.sort(function (a, b) {
      return a.dateTime.getDate() - b.dateTime.getDate();
    });

    punchClock.mealBreakReasonConfiguration = {
      shouldDisplayColumn : punchClockOutput.mealBreakReason.shouldDisplayColumn,
      dropDownOptionsWithToolTips : punchClockOutput.mealBreakReason.dropDownOptionsWithToolTips,
      dateInfo : punchClockOutput.mealBreakReason.dateInfo
    };
    punchClock.chargeCodeConfiguration = {
      shouldDisplayColumn : punchClockOutput.configuration.shouldDisplayChargeCode,
      chargeCodeDateInfo : punchClockOutput.chargeCodeInfo.chargeCodeDateInfoList
    };
    return punchClock;
  }

  public static mapPunchClockInput(punchClock: PunchClock, periodEnd: Date, user: string, isRestHours: boolean = false): SavePunchClockEntriesInput {
    let punchClockInput = new SavePunchClockEntriesInput();
    punchClockInput.enterpriseId = user;
    punchClockInput.periodEnd = periodEnd;
    punchClockInput.entries = punchClock.entries.map(entry => {
      let entryFromDB: PunchClockEntryFromDb = new PunchClockEntryFromDb();
      entryFromDB = TimeServiceMapper.assignPunchClockEntry(entry);
      return entryFromDB;
    });
    punchClockInput.entries.forEach(entry => {
      entry.isRestHours = isRestHours;
    });
    return punchClockInput;
  }

  private static mapAssignments(projectInfo: ProjectInfo[] | any[]): TimeEntryAssignment[] {
    let assignments = this.mapChargeCodes(projectInfo);
    assignments.sort((a, b) => {
      return (a.type < b.type) ? -1 : (a.type > b.type) ? 1 :
        (a.subtype < b.subtype) ? -1 : (a.subtype > b.subtype) ? 1 :
          (a.countryRegion < b.countryRegion) ? -1 : (a.countryRegion > b.countryRegion) ? 1 :
            (a.client < b.client) ? -1 : (a.client > b.client) ? 1 :
              (a.description < b.description) ? -1 : (a.description > b.description) ? 1 :
                (a.code < b.code) ? -1 : (a.code > b.code) ? 1 : 0;
    });
    return assignments;
  }

  private static assignPunchClockEntry(entry: PunchClockEntry): PunchClockEntryToSave {
    let entryFromDB = new PunchClockEntryToSave();
    entryFromDB.dateTime = entry.dateTime;
    entryFromDB.daySequenceNbr = entry.sequenceNumber;
    entryFromDB.workStartTime = new WorkStartTime();
    entryFromDB.workStartTime.hours = entry.workStartTime ? entry.workStartTime.hours : null;
    entryFromDB.workStartTime.minutes = entry.workStartTime ? entry.workStartTime.minutes : null
    
    entryFromDB.workEndTime = new WorkEndTime();
    entryFromDB.workEndTime.hours = entry.workEndTime ? entry.workEndTime.hours : null;
    entryFromDB.workEndTime.minutes = entry.workEndTime ? entry.workEndTime.minutes : null;

    entryFromDB.mealEndTime = new MealEndTime();
    entryFromDB.mealEndTime.hours = entry.mealEndTime ? entry.mealEndTime.hours : null;
    entryFromDB.mealEndTime.minutes = entry.mealEndTime ? entry.mealEndTime.minutes : null;
    
    entryFromDB.mealStartTime = new MealStartTime();
    entryFromDB.mealStartTime.hours = entry.mealStartTime ? entry.mealStartTime.hours : null;
    entryFromDB.mealStartTime.minutes = entry.mealStartTime ? entry.mealStartTime.minutes : null;
    entryFromDB.breakDuration = entry.breakDuration;
    entryFromDB.mealBreakReason = entry.mealBreakReason;
    entryFromDB.wbsExternalNbr = entry.wbsExternalNbr;
    return entryFromDB;
  }

  private static mapWorkScheduleChargesToDto(wsg: WorkScheduleGroup): WorkScheduleInputDto[] {

    if (wsg.isStatic) return null;

    let charges: WorkScheduleChargeDto[] = [];

    for (let i = 0; i < wsg.WorkSchedules.length; i++) {
      let ws = wsg.WorkSchedules[i];

      switch (wsg.type) {
        case WorkScheduleType.PersonalSchedule:

          ws.charges.forEach((wsc: SimpleWorkScheduleCharge) => {

            let chargeDto = new WorkScheduleChargeDto();
            chargeDto.dayOfWeek = wsc.getDayName();
            chargeDto.date = wsc.getFullDay();
            chargeDto.value = wsc.charge.toString();
            chargeDto.shitfType = ws.shift;

            if (chargeDto.shitfType == WorkScheduleType.NightShift) {

              let index = charges.findIndex(item =>
                item.dayOfWeek === chargeDto.dayOfWeek)

              if (index >= 0) {
                if (Number(chargeDto.value) != 0.0) {
                  charges[index].shitfType = WorkScheduleType.NightShift;
                  charges[index].value = chargeDto.value;
                }
              } else {
                charges.push(chargeDto);
              }
            } else {
              charges.push(chargeDto);
            }
          });

          break;

        case WorkScheduleType.CustomForPeriod:
          ws.charges.forEach((wsc: WorkScheduleChargeForPeriod) => {

            let chargeDto = new WorkScheduleChargeDto();

            chargeDto.date = wsc.date;
            chargeDto.value = wsc.charge.toString();
            chargeDto.shitfType = ws.shift;

            if (chargeDto.shitfType == WorkScheduleType.NightShift) {
              let index = charges.findIndex(item => item.date.valueOf() == wsc.date.valueOf());
              if (index >= 0) {
                if (Number(chargeDto.value) != 0.0) {
                  charges[index].shitfType = WorkScheduleType.NightShift;
                  charges[index].value = chargeDto.value;
                }
              } else {
                charges.push(chargeDto);
              }
            } else {
              charges.push(chargeDto);
            }
          });

          break;
        default:
          throw Error(`Unable to identify Work Schedule Type: ${wsg.type}`);
      }
    }
    return charges;
  }

  private static mapWorkScheduleChargesCannotAllowWeekendsToDto(wsg: WorkScheduleGroup): WorkScheduleInputDto[] {

    if (wsg.isStatic) return null;

    let charges: WorkScheduleChargeDto[] = [];

    for (let i = 0; i < wsg.WorkSchedules.length; i++) {
      let ws = wsg.WorkSchedules[i];

      switch (wsg.type) {
        case WorkScheduleType.PersonalSchedule:
        case WorkScheduleType.CustomForPeriod:
          ws.charges.forEach((wsc: SimpleWorkScheduleCharge) => {

            let chargeDto = new WorkScheduleChargeDto();
            chargeDto.dayOfWeek = wsc.getDayName();
            chargeDto.value = wsc.charge.toString();
            chargeDto.date = wsc.getFullDay();
            chargeDto.shitfType = ws.shift;
            charges.push(chargeDto);
          });

          break;
        default:
          throw Error(`Unable to identify Work Schedule Type: ${wsg.type}`);
      }
    }
    return charges;
  }

  public static mapShiftScheduleToInput(shiftSchedule: ShiftScheduleRowData[], timePeriodDates: Date[], isByPeriod: boolean): ShiftScheduleInput {
    let input = new ShiftScheduleInput();

    shiftSchedule.forEach((item) => {
      var length = 0;
      if (isByPeriod)
        length = timePeriodDates.length;
      else
        length = 7;

      switch (item.description) {
        case "Start Time":
          input.startTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          input.restDays = TimeServiceMapper.mapShiftRestDaysToInput(item, length);
          break;
        case "Shift Start Time":
          input.startTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          input.restDays = TimeServiceMapper.mapShiftRestDaysToInput(item, length);
          break;
        case "End Time":
          input.endTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          break;
        case "Shift End Time":
          input.endTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          break;
        case "Break Length":
          input.breakLengths = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          break;
        case "Break Start Time":
          input.breakStartTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          break;
        case "Break End Time":
          input.breakEndTimes = TimeServiceMapper.mapShiftScheduleDtoToInput(isByPeriod, timePeriodDates, item, length);
          break;
        case "Total Hours":
          return input;
      }
    })

    return input;
  }

  private static mapShiftScheduleDtoToInput(isByPeriod: boolean, timePeriodDates: Date[], item: ShiftScheduleRowData, length: number): ShiftScheduleDto[] {
    let dtos = [];
    let daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    for (var i = 0; i < length; i++) {
      let dto = new ShiftScheduleDto();
      dto.date = isByPeriod ? timePeriodDates[i] : null;
      dto.dayOfWeek = isByPeriod ? moment(timePeriodDates[i]).format('dddd') : daysOfWeek[i];
      dto.value = item.entries[i].visible ? item.entries[i].value.toString() : "";
      dtos.push(dto);
    }

    return dtos;
  }

  private static mapShiftRestDaysToInput(item: ShiftScheduleRowData, length: number): boolean[] {
    let values = [];
    for (var i = 0; i < length; i++) {
      if (item.entries[i].visible) {
        values.push(0);
      }
      else
        values.push(1);
    }

    return values;
  }

  public static mapAllowanceRequestEntriesInput(enterpriseId: string, periodEnd: Date, timeCategoryCode: string, entries: AllowanceRequestEntryOutput[]): AllowanceRequestEntriesInput {
    const allowanceRequestInput: AllowanceRequestEntriesInput = new AllowanceRequestEntriesInput();
    allowanceRequestInput.enterpriseId = enterpriseId;
    allowanceRequestInput.periodEnd = periodEnd;
    allowanceRequestInput.timeCategoryCode = timeCategoryCode;
    allowanceRequestInput.entries = entries.map(entry => {
        const newEntry: ViewAllowanceRequestEntry = new ViewAllowanceRequestEntry();
        newEntry.date = entry.date;
        newEntry.daySequenceNbr = entry.daySequenceNbr;
        newEntry.selectedApprover = entry.approverInfo.selectedApprover;
        newEntry.selectedChargeCode = entry.chargeCodeInfo.selectedChargeCode;
        newEntry.selectedReviewer = entry.reviewerInfo.selectedReviewer;
        newEntry.declaredHoliday = entry.declaredHoliday.isDeclaredHoliday;
        return newEntry;
    });
    return allowanceRequestInput;
  }

  public static mapOvertimeRequestEntriesInput(enterpriseId: string, periodEnd: Date, timeCategoryCode: string, entries: OvertimeRequestEntry[]): AllowanceRequestEntriesInput {
    const allowanceRequestInput: AllowanceRequestEntriesInput = new AllowanceRequestEntriesInput();
    allowanceRequestInput.enterpriseId = enterpriseId;
    allowanceRequestInput.periodEnd = periodEnd;
    allowanceRequestInput.timeCategoryCode = timeCategoryCode;
    allowanceRequestInput.entries = entries.map(entry => {
        const newEntry: ViewAllowanceRequestEntry = new ViewAllowanceRequestEntry();
        newEntry.date = entry.date;
        newEntry.selectedApprover = entry.approverInfo.selectedApprover;
        newEntry.selectedChargeCode = entry.chargeCodeInfo.selectedChargeCode;
        newEntry.reasonForOvertimeId = entry.reasonForOvertimeInfo?.selectedReasonForOvertime?.id;
        newEntry.startTimeHour = entry.startTimeInfo?.hours;
        newEntry.startTimeMinutes = entry.startTimeInfo?.minutes;
        newEntry.endTimeHour = entry.endTimeInfo?.hours;
        newEntry.endTimeMinutes = entry.endTimeInfo?.minutes;
        newEntry.description = entry.descriptionInfo?.description;
        newEntry.daySequenceNbr = entry.daySequenceNbr;
        newEntry.overtimeSplitHours = entry.overtimeSplitHoursInfo?.overtimeSplitHours;
        return newEntry;
    });
    return allowanceRequestInput;
  }

  public static mapTimeCategoryRequestEntriesInput(enterpriseId: string, periodEnd: Date, timeCategoryCode: string, entries: TimeCategoryRequestEntry[]): TimeCategoryRequestInput {
    const timeCategoryRequestInput: TimeCategoryRequestInput = new TimeCategoryRequestInput();
    timeCategoryRequestInput.enterpriseId = enterpriseId;
    timeCategoryRequestInput.periodEnd = periodEnd;
    timeCategoryRequestInput.timeCategoryCode = timeCategoryCode;
    timeCategoryRequestInput.entries = entries.map(entry => {
      const newEntry : TimeCategoryRequestInputEntry = new TimeCategoryRequestInputEntry();
      newEntry.date = entry.date;
      newEntry.daySequenceNbr = entry.daySequenceNbr;
      newEntry.values = entry.values.map( entryValue =>{
        const newEntryValue : TimeCategoryRequestInputEntryValue = new TimeCategoryRequestInputEntryValue();
        newEntryValue.key = entryValue.key;
        newEntryValue.value = entryValue.value;
        return newEntryValue;
      });
      return newEntry;})
    return timeCategoryRequestInput;
  }

  public static mapOvertimeRequest(allowanceRequestOutput: AllowanceRequestOutput): OvertimeRequest {
    let overtimeRequest = new OvertimeRequest();
    overtimeRequest.title = allowanceRequestOutput.title;
    overtimeRequest.isReadOnly = allowanceRequestOutput.isReadOnly;
    overtimeRequest.allowDailySplits = allowanceRequestOutput.allowDailySplits;
    overtimeRequest.displayChargeCodeColumn = allowanceRequestOutput.displayChargeCodeColumn;
    overtimeRequest.displayStartEndTimeFields = allowanceRequestOutput.displayStartEndTimeFields;
    overtimeRequest.minutesIncrement = allowanceRequestOutput.minutesIncrement;
    overtimeRequest.displayReasonForOvertimeField = allowanceRequestOutput.displayReasonForOvertimeField;
    overtimeRequest.displayDescriptionField = allowanceRequestOutput.displayDescriptionField;
    overtimeRequest.displayPopMessageAU = allowanceRequestOutput.displayPopMessageAU;
    overtimeRequest.displayOTMessage = allowanceRequestOutput.displayOTMessage;
    allowanceRequestOutput.allowanceRequestEntryDtoList.map(entry => {
      let overtimeRequestEntry = new OvertimeRequestEntry();
      overtimeRequestEntry.date = entry.date;
      overtimeRequestEntry.chargeCodeInfo = entry.chargeCodeInfo;
      overtimeRequestEntry.approverInfo = entry.approverInfo;
      overtimeRequestEntry.hours = entry.hours;
      overtimeRequestEntry.isPrepopulatedEntry = entry.isPrepopulatedEntry;
      overtimeRequestEntry.isHoliday = entry.isHoliday;
      overtimeRequestEntry.startTimeInfo = entry.startTimeInfo;
      overtimeRequestEntry.endTimeInfo = entry.endTimeInfo;
      overtimeRequestEntry.reasonForOvertimeInfo = entry.reasonForOvertimeInfo;
      overtimeRequestEntry.descriptionInfo = entry.descriptionInfo;
      overtimeRequestEntry.daySequenceNbr = entry.daySequenceNbr;
      overtimeRequestEntry.overtimeSplitHoursInfo = entry.overtimeSplitHoursInfo;
      overtimeRequest.entries.push(overtimeRequestEntry);
    });
    return overtimeRequest;
  }
  
  public static mapWorkShiftToInput(timeCategoryTask: TimeCategoryTask, timeSheet: TimeSheet): WorkShift {
    let workShift = new WorkShift();
    workShift.name = timeCategoryTask.name;
    workShift.UiType = timeCategoryTask.UiType;
    workShift.code = timeCategoryTask.code;
    workShift.editable = timeCategoryTask.isEditable;
    workShift.shiftTypes = timeSheet.timeCategoryForWorkShift?.sort((a, b) => parseInt(a.key.slice(1)) - parseInt(b.key.slice(1)));
    workShift.entries = timeCategoryTask.charges.map(timeCategoryCharge => {
      let workShiftEntry = new WorkShiftEntry();
      workShiftEntry.dateTime = timeCategoryCharge.date;
      workShiftEntry.shiftType = timeCategoryCharge && timeCategoryCharge.value  && workShift.shiftTypes ? workShift.GetValue(timeCategoryCharge.value): '';
      workShiftEntry.tooltip = timeCategoryCharge.tooltip;
      workShiftEntry.hasError = timeCategoryCharge.hasError;
      workShiftEntry.hasAdjustment = timeCategoryCharge ? timeCategoryCharge.hasAdjustment : false;
      workShiftEntry.editable = timeCategoryCharge.isEditable && timeSheet.isEditable;
      workShiftEntry.nonWorkingDay = timeSheet.timeSheetDates.find(x => x.date==workShiftEntry.dateTime).isHoliday || timeSheet.timeSheetDates.find(x => x.date==workShiftEntry.dateTime).isWeekend;
      return workShiftEntry;
    });
    return workShift;
  }

  public static mapWorkShiftToOutput(workShiftTask: WorkShift): TimeCategoryTask {
    let timeCategoryTask = new TimeCategoryTask();
    timeCategoryTask.name = workShiftTask.name;
    timeCategoryTask.UiType = workShiftTask.UiType;
    timeCategoryTask.code = workShiftTask.code;
    timeCategoryTask.isEditable = workShiftTask.editable;
    timeCategoryTask.charges = workShiftTask.entries.map(workShiftEntry => {
      let timeCategoryCharge = new TimeCategoryCharge();
      timeCategoryCharge.date = workShiftEntry.dateTime;
      timeCategoryCharge.value = workShiftEntry && workShiftEntry.shiftType ? workShiftTask.GetCode(workShiftEntry.shiftType): '';
      timeCategoryCharge.tooltip = workShiftEntry.tooltip;
      timeCategoryCharge.hasError = workShiftEntry.hasError;
      timeCategoryCharge.hasAdjustment = workShiftEntry ? workShiftEntry.hasAdjustment : false;
      timeCategoryCharge.isEditable = workShiftEntry.editable;
      return timeCategoryCharge;
    });
    return timeCategoryTask;
  }

/**
 * On workshift popup save map selected workshift(S1,S2 etc.) to TimeSheet value
 * @param workShiftTask TimeCategoryRequestOutput
 * @returns TimeCategoryTask with s1,s2 etc. value.
 */
  public static mapTHWorkShiftToOutput(workShiftTask: TimeCategoryRequestOutput, sheet: TimeSheet): TimeCategoryTask {
    let timeCategoryTask = new TimeCategoryTask();
    timeCategoryTask.charges = workShiftTask.entries.map(workShiftEntry => {
      const workShiftEntryValue = workShiftEntry.values.filter(work => work.key == WorkShiftKey)[0];
      const hoursEnteredForTheDay = sheet.timeEntry.totals.find(x => x.date.getDate() == workShiftEntry.date.getDate());
      let timeCategoryCharge = new TimeCategoryCharge();
      timeCategoryCharge.date = workShiftEntry.date;
      timeCategoryCharge.value = hoursEnteredForTheDay.totals ? workShiftEntryValue.value ? workShiftEntryValue.value.split(' ')[0] : '' : null;
      timeCategoryCharge.tooltip = null;
      timeCategoryCharge.hasError = null;
      timeCategoryCharge.hasAdjustment = null;
      timeCategoryCharge.isEditable = null;
      return timeCategoryCharge;
    });
    return timeCategoryTask;
  }
}
